import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

import { Divider } from 'semantic-ui-react'
import headerStyle from '../styles/navbar.module.scss'

const FaqsNavBar = () => (
  <>
    <div className="container-fluid px-2 my-4">
      <h1 className="text-center pt-4">F.A.Q</h1>
      <ul
        className={classNames(
          headerStyle.navBar,
          'd-flex flex-wrap  align-items-center justify-content-center'
        )}
      >
        <li className={classNames(headerStyle.navBarItem, 'mb-4')}>
          <Link
            to="/faq/general"
            activeClassName={headerStyle.activeNavBarItem}
            className={headerStyle.navBarLink}
          >
            General Questions
          </Link>
        </li>
        <li className={classNames(headerStyle.navBarItem, 'mb-4')}>
          <Link
            to="/faq/pedi-spa-chair"
            activeClassName={headerStyle.activeNavBarItem}
            className={headerStyle.navBarLink}
          >
            Pedi-Spa Chair Questions
          </Link>
        </li>
        <li className={classNames(headerStyle.navBarItem, 'mb-4')}>
          <Link
            to="/faq/shipping"
            activeClassName={headerStyle.activeNavBarItem}
            className={headerStyle.navBarLink}
          >
            Shipping Questions
          </Link>
        </li>
      </ul>
      <Divider />
    </div>
  </>
)

export default FaqsNavBar
